import React from 'react'


const Orange = () => {
  return (
    <div className=' pb-10'>
    <img src="/Assets/Group24.png" alt="" />
    <div className='xs:px-[18%] px-6 '>
    <div className='grid lg:grid-cols-10 shadow-4xl shadow-[#FF701F]/30  rounded-3xl bg-[#FF701F]/80 text-white '>
        <div className='lg:col-span-6 px-[5%] lg:pl-[12%] lg:pb-[14%] pt-[8%]'>
            <h1 className='2xl:text-3xl sm:text-2xl xl:text-2xl lg:text-lg font-semibold'>Personalized One-on-One Sessions: Your Unique Path to Wellness</h1>
            <p className='pt-[8%] w-[95%]'>Experience the power of dedicated attention. Our one-on-one sessions are crafted to suit your specific needs and goals, ensuring a focused journey towards a healthier and more balanced you.</p>
        </div>
        <div className='lg:col-span-4 flex justify-center  lg:relative'>
            <img className='lg:absolute -top-20' src="/Assets/enroll.png" alt="" />
        </div>
    </div>
    <div className=' flex justify-center relative   -top-10 '>

    <div className='rounded-full w-[90%] shadow-4xl shadow-[#FF701F]/30   py-4 flex justify-between items-center px-[10%] bg-white'>
        <p className='sm:w-[55%] font-semibold text-xs xs:text-sm md:block hidden sm:text-base'>Book Your <span className='text-[#FF701F]'>One-to-one</span> Session Today!</p>
        <a href='https://api.whatsapp.com/send/?phone=918383078277&text=Hi%2C%0D%0A%0D%0AI+would+like+to+have+a+personal+session&type=phone_number&app_absent=0'>
        <button className='bg-[#FF701F] sm:text-base text-xs mx-auto xs:text-sm rounded-3xl hover:text-primary hover:bg-white border-2 border-primary text-white font-semibold px-6 py-2'>Book Now</button>
        </a>

    </div>
    </div>

    </div>
      
    </div>
  )
}

export default Orange