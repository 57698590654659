import React from 'react'
import { Home2, Swip, Workshops } from '../components'

const Workshop = () => {
  return (
    <div>
        <Home2 />
        <Swip />
        {/* <Workshops /> */}
    </div>
  )
}

export default Workshop