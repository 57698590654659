import React from "react";



const Past = () => {
  return (
    <div className="">
      <div
        className="  bg-[#FFF5EF]"
        style={{
          backgroundImage: `url('/Assets/Group18.png')`,
          backgroundPosition: "center",
          backgroundSize: "cover",
        }}
      >
        <div className="flex px-4 flex-col items-center justify-center">
          <img className="" src='/Assets/Group25.png' alt="" />
          {/* <button className="mb-12 bg-[#FF701F] text-white px-10 py-4 rounded-3xl font-semibold">Know More</button> */}
        </div>
      </div>
    </div>
  );
};

export default Past;