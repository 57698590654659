import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, Scrollbar, A11y, Autoplay } from "swiper/modules";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import "swiper/css";
import FeedCard from "../Cards/FeedCard";
import FadeUpDown from "../Animation/FadeUpDown";

const Feedback = ({items}) => {
  return (
    <section className="px-[15%] py-20">
      <div>
        <FadeUpDown yOffset={15} delay={0.4}>
        <h1 className="text-center text-5xl font-semibold">
          Check what's our client
        </h1>
        <h1 className="text-primary py-3 text-center text-5xl font-semibold">
          say about us!
        </h1>
        </FadeUpDown>

        <Swiper className=""
          modules={[Autoplay, Navigation, Pagination, Scrollbar, A11y]}
          loop={true}
          spaceBetween={50}
          slidesPerView={1}
          autoplay={{
            delay: 2500,
            disableOnInteraction: false,
          }}
          pagination={{ clickable: true }}
          breakpoints={{
            850: {
                slidesPerView: 1
            }
          }}
          onSwiper={(swiper) => console.log(swiper)}
          onSlideChange={() => console.log("slide change")}
        >
          {
            items.map((item) => (
                <SwiperSlide className="py-10">
            <FeedCard {...item} />
          </SwiperSlide>
            ))
          }
          
        </Swiper>
      </div>
    </section>
  );
};

export default Feedback;


Feedback.defaultProps = {
    items: [
        {
            img: "/Assets/avatar.png",
            namee: "Name",
            country: "Panihari Member",
            text: "I am following Monaa Mam's diet from last three months, with the help of just diet eating timing n exercises i lost 6 kgs weight. Even my gathia pains, uric acid & celestrol report are better and normal now. I m so happy ki Monaa mam ne mujeh healthy lifestyles ki knowledge Di. Do give a try Monaa mam you are great person God bless you"
        },
        {
            img: "/Assets/avatar.png",
            namee: "Name",
            country: "Panihari Member",
            text: "Maam you are sooo sweet and very down to earth.... Maam muche ap bhut bhut bhut jayada ache lagte ho jitna bola or likhu utna hi kam hai apke liye first time maine apni life mai itni down to earth dietitian dekhi hai Jo itne pyar se sooo many people's ko handle krti hai and sabse badi bat maam ki ap free of cost logo ka weight loose karwa rai hain.i wish ki bhagwan apko apki life mai har success de or Jo bhi apka dream hai wo bhut jald pura ho.... Love you maam"
        },
        {
            img: "/Assets/avatar.png",
            namee: "Name",
            country: "Panihari Member",
            text: "Mam it's really really appreciable selfless efforts, hardwork, service to society especially for women really really speechless"
        },
        {
            img: "/Assets/avatar.png",
            namee: "Name",
            country: "Panihari Member",
            text: "It was indeed an amazing workshop ma'am The way you explain things cut to cut is brilliant and your patience with which you explain and motivate is remarkable"
        },
        {
            img: "/Assets/avatar.png",
            namee: "Name",
            country: "Panihari Member",
            text: "Mam very useful tips excellent knowledge long life mam God bless you always mam we Love you very nice Workshop Thanku soo much"
        },
        {
            img: "/Assets/avatar.png",
            namee: "Name",
            country: "Panihari Member",
            text: "Ma'am ur an angel in our lives as full time u are searching and telling all sorts of tips,take so much time and effort and energy,the session u gv us was just awesome,no words to describe it,tips tricks, alternative what all u hv to tell us, my god I think no other dietician or coach would do that ur a life changer"
        },
        {
            img: "/Assets/avatar.png",
            namee: "Name",
            country: "Panihari Member",
            text: "Mona Mam, I have reduced 11 kgs in 3 months, by following your diet plans, yoga and exercises....... It has cured my PCOD and Cervical problem also.... Thank you so much mam for your constant support and guidance"
        },
          
        
    ]
}