import React from 'react'
import {TiTick} from 'react-icons/ti'

const Vege = () => {
    const List=[
        {
            title:'Normal routine diet chart',
        },
        {
            title:'Intermittent fasting',
        },
        {
            title:'DIP Diet',
        },
        {
            title:'Thyroid Diet',
        },
        {
            title:'Diebeties Diet',
        },
        {
            title: 'Celebrity Diet'
        }
    ]
  return (
    <div className='xl:pl-40 lg:pl-28 py-10 px-6 lg:pt-32 lg:pr-10 bg-[#FFF5EF]'>
      <div className='grid lg:grid-cols-2'>
            <div className='order-2 lg:order-1'>
                <h1 className='xl:text-4xl  text-lg sm:text-3xl leading-relaxed font-semibold'>Monaa Yoga: <span className='text-[#FF701F]'>Expert Diet Plans</span> for Your Health and Wellnesss</h1>
                <p className='sm:text-lg sm:py-6 py-3 lg:py-4 sm:w-[80%]  text-gray-600'>Achieve your health and wellness goals with Monaa Yoga’s Expert Diet Plans - a variety of customized diet plans that suit your individual needs and preferences. Learn how to nourish your body and mind with sustainable dietary habits that support your well-being.</p>
                <div className='grid xl:pt-10  lg:pt-6 pt-4 gap-6 sm:grid-cols-2'>
                {
                    List.map((Lists)=>(
                        <div className='flex  items-center gap-1'><TiTick className='text-green-500' size={20}/>
                            <p>{Lists.title}</p>
                        </div>
                    ))
                }

                </div>
            </div>
            <div className='pt-10 order-1 lg:order-2 flex flex-col items-center'>
                <div><img src="/Assets/Group6.png" alt="" /></div>
                <div className='pl-[60%]'><img src="/Assets/Group5.png" alt="" /></div>
            </div>
      </div>
    </div>
  )
}

export default Vege