import React from 'react'

const Home = () => {
  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };

  return (
    <div className='px-6 md:px-8 lg:px-0 pt-12  pb-12 md:pb-0  md:xl:pl-32 lg:pl-20 bg-[#FFF5EF]'>

      <div className='grid lg:grid-cols-2 '>
        <div className='lg:pt-32 relative md:pl-12 order-2 lg:order-1 md:pb-10 lg:pb-0 pb-0'>
        <img className='md:absolute w-[25%] md:right-24 top-4 hidden lg:-right-[18%]' src="/Assets/Group2.png" alt="" />
            <p className=' text-[6vw] lg:text-[3.3vw] font-bold'>Find your <span className='text-[#FF701F] '>Wellness & Vitality</span> With Monaa Yoga</p>
            <p className=' sm:text-lg py-6 md:text-xl font-semibold md:py-12 '>Experience a Transformational Journey to a Healthier and Happier You with Monaa Yoga - The Ultimate Destination for Joyful Wellness through Breathwork and Yoga.</p>
            <a href="https://www.instagram.com/monaayoga/" onClick={scrollToTop}>
            <button className='py-2 px-7 sm:text-lg font-semibold bg-[#FF701F] text-white rounded-3xl border-2 border-primary hover:bg-white hover:text-primary'>Join Our Community</button>
            </a>
        </div>
        <div className='order-1 flex justify-center lg:order-2 items-center'>
            <img className='h-[85%]' src="/Assets/Group1.png" alt="" />
        </div>
       

        </div>
      </div>
    
  )
}

export default Home