export const API_ROUTES = {
  USER: {
    LOGIN: "/login",
    SIGNUP: "/user",
    AUTH_ME: "/autMe",
    COURSES: "/course/user-course",
    GET_OTP: "/users/get-otp",
    RESET_PASSWORD: "/user/reset-password",
    LOGIN_WITH_GOOGLE: "/login-with-google",
  },
  PAYMENT: {
    CREATE: "/order",
    VERIFY: "/order/verify-payment",
    FAILED: "/order/failed-payment",
  },
};
