import React from "react";

// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";

// import required modules
import { FreeMode, Autoplay } from "swiper/modules";

const Result = () => {
  const List = [
    {
      img: "/Assets/r1.jpg",
    },
    {
      img: "/Assets/r2.jpg",
    },
    {
      img: "/Assets/r3.jpg",
    },
    {
      img: "/Assets/r4.jpg",
    },
    {
      img: "/Assets/r5.jpg",
    },
    {
      img: "/Assets/r6.jpg",
    },
    {
      img: "/Assets/r7.jpg",
    },
    {
      img: "/Assets/r8.jpg",
    },
    {
      img: "/Assets/r9.jpg",
    },
    {
      img: "/Assets/r10.jpg",
    },
    {
      img: "/Assets/r11.jpg",
    },
    {
      img: "/Assets/r12.jpg",
    },
    {
      img: "/Assets/r13.jpg",
    },
    {
      img: "/Assets/r14.jpg",
    },
    {
      img: "/Assets/r15.jpg",
    },
    {
      img: "/Assets/r16.jpg",
    },
    {
      img: "/Assets/r17.jpg",
    },
    {
      img: "/Assets/r18.jpg",
    },
    {
      img: "/Assets/r19.jpg",
    },
    {
      img: "/Assets/r20.jpg",
    },
    {
      img: "/Assets/r21.jpg",
    },
  ];
  return (
    <div className="">
      <div
        className=" pb-28"
        style={{
          backgroundImage: `url('/Assets/Group11.png')`,
          backgroundSize: "cover",
        }}
        
      >
        <div className="flex flex-col  items-center">
          <h1 className="text-5xl pb-20 pt-20 py-6 font-semibold text-center">
            Happy <span className="text-[#FF701F] "> Members</span>
          </h1>
          <div className=" w-full ">
            <Swiper
              slidesPerView={3}
              centeredSlides={true}
              grabCursor={true}
              spaceBetween={30}
              // freeMode={true}
              autoplay={{
                delay: 2500,
                disableOnInteraction: false,
              }}

              breakpoints={{
                0: {
                  slidesPerView: 1,
                  spaceBetween: 30,
                },
                640: {
                  slidesPerView: 1,
                  spaceBetween: 30,
                },
                768: {
                  slidesPerView: 2,
                  spaceBetween: 30,
                },
                1260: {
                  slidesPerView: 3,
                  spaceBetween: 30,
                },
              }}
              modules={[FreeMode, Autoplay]}
              className="  "
            >
              {List.map((Lists) => (
                <SwiperSlide>
                    <div className="gap-10 grid place-content-center">
                  <img className="w-[80%] rounded-2xl" src={Lists.img} alt="result" />
                    </div>
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Result;
