import { z } from "zod";

export const loginSchema = z.object({
  email: z
    .string({ required_error: "Email is required" })
    .email({ message: "Invalid Email" }),
  password: z
    .string({ required_error: "Password is required" })
    .min(1, "Invalid password"),
});

export const userSchema = z.object({
  name: z.string().min(1, "invalid name"),
  email: z.string().email("invalid email"),
  password: z.string().min(1, "invalid password"),
  phone: z.string().min(1, "invalid phone number"),
  // height: z.string().min("", "invalid height"),
  // weight: z.string().min(1, "invalid weight"),
  // age: z.string().min(1, "invalid age"),
  // workout: z.string().min(1, "invalid workout"),
  // sleep_schedule: z.string().min(1, "invalid sleep schedule"),
  // mental_status: z.string().min(1, "invalid mental status"),
  // water_intake: z.string().min(1, "invalid water intake"),
  // sleeping_time: z.string().min(1, "invalid sleeping time"),
  // health_problems: z.string().min(1, "invalid"),
  // working_persion: z.string().min(1, "invalid "),
});
