import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { FaBars } from "react-icons/fa";
import { ImCross } from "react-icons/im";
import { useSelector } from "react-redux";
import useVerifyToken from "../hooks/useVerifyToken";
import { MenuButton } from "../components";

const Navbar = () => {
  const { isFetching } = useVerifyToken();
  const { user } = useSelector((state) => state);

  const [nav, setNav] = useState(false);

  const handleNav = () => {
    setNav(!nav);
  };

  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };

  const [colorChange, setColorchange] = useState(false);
  const changeNavbarColor = () => {
    if (window.scrollY >= 80) {
      setColorchange(true);
    } else {
      setColorchange(false);
    }
  };
  window.addEventListener("scroll", changeNavbarColor);

  return (
    <section className="">
      <div
        className={
          nav
            ? "bg-primary/[0.9] h-screen fixed z-30 w-screen ease-in-out duration-1000 pt-[20%] px-5 text-center lg:hidden"
            : "hidden"
        }
      >
        {links.map((link) => (
          <div className="py-7 border-b-[1px] border-white" onClick={handleNav}>
            <Link to={link.link} onClick={scrollToTop}>
              <button className="font-medium text-white text-xl">
                {link.text}
              </button>
            </Link>
          </div>
        ))}
        <div className="py-7" onClick={handleNav}>
          <Link to="/contact" onClick={scrollToTop}>
            <button className="text-primary w-full bg-white px-6 py-2 rounded-xl border-2 border-white hover:bg-primary hover:text-white font-medium">
              Contact Us
            </button>
          </Link>
        </div>
      </div>
      <div
        className={
          colorChange
            ? "fixed w-full bg-white z-50 shadow-xl"
            : "fixed w-full z-50"
        }
      >
        <div className="px-[5%] py-4 flex justify-between items-center">
          <div className="lg:w-1/6 w-1/2 md:w-1/3">
            <Link to="/" onClick={scrollToTop}>
              <img className="" src="/Assets/Logo.png" alt="" />
            </Link>
          </div>
          <div className="lg:flex justify-center gap-[7vw] hidden">
            {links.map((link) => (
              <Link to={link.link} onClick={scrollToTop}>
                <button className="font-medium hover:text-primary">
                  {link.text}
                </button>
              </Link>
            ))}
          </div>
          <div className="lg:flex justify-center items-center hidden gap-3">
            <Link to="/contact" onClick={scrollToTop}>
              <button className="text-white bg-primary px-4 py-2 rounded-xl border-2 border-primary hover:bg-white hover:text-primary font-medium">
                Contact Us
              </button>
            </Link>
            {!user?.name ? (
              <Link to={"/login"}>
                <button className="text-white bg-primary px-4 py-2 rounded-xl border-2 border-primary hover:bg-white hover:text-primary font-medium">
                  Login
                </button>
              </Link>
            ) : (
              <MenuButton name={user?.name} />
            )}
          </div>
          <div className="lg:hidden cursor-pointer flex items-center gap-5">
            {!user?.name ? (
              <Link to={"/login"}>
                <button className="text-white bg-primary px-4 py-2 rounded-xl border-2 border-primary hover:bg-white hover:text-primary font-medium">
                  Login
                </button>
              </Link>
            ) : (
              <MenuButton name={user?.name} />
            )}
            <div className="" onClick={handleNav}>
              {!nav ? (
                <FaBars className="text-primary" />
              ) : (
                <ImCross className="text-primary bg-white" />
              )}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Navbar;

const links = [
  {
    text: "Home",
    link: "/",
  },
  {
    text: "Workshop",
    link: "/workshop",
  },
  {
    text: "About Us",
    link: "/about",
  },
  {
    text: "Learn Yoga",
    link: "/yoga",
  },
  {
    text: "Courses",
    link: "/courses",
  },
];
