import axios from "axios";

export const server = axios.create({
  baseURL: process.env.REACT_APP_SERVER_URL,
  headers: {
    // "Content-Type": "applicatioin/json",
  },
});

server.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("token");
    if (token) {
      config.headers["Authorization"] = `BEARER ${token}`;
    }
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);
