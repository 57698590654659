import React from 'react'
import { Cards, Homes, Monaa, Morbi, Panihari, Vege } from '../components'

const Home = () => {
  return (
    <div>
        <Homes />
        <Morbi />
        <Vege />
        <Monaa />
        <Panihari />
        {/* <Cards /> */}
    </div>
  )
}

export default Home