import React from 'react'


const Arcu2 = () => {
  return (
    <div className="bg-[#FFF5EF] py-12 ">
    <div
      className="py-[7%] "
      style={{
        backgroundImage: `url('/Assets/Group23.png')`,
        backgroundSize: "cover",
        backgroundPosition: "center",
      }}
    >
      <div className="grid lg:grid-cols-2">
        <div className="">
          <div className="flex items-center">
          <img className='w-[80%]' src='/Assets/Group28.png' alt="" />
          </div>
          
        </div>
        <div className="sm:w-[80%] flex flex-col justify-center md:w-[70%] pl-[10%] lg:pl-0">
          <h1 className="2xl:text-4xl sm:text-2xl  text-xl lg:text-3xl   font-semibold">
           My Yoga Journey:  <span className="text-[#FF701F]">37 years </span>{" "}of Passion & Dedication
          </h1>
          

          <p className=" sm:text-lg py-12 text-gray-600 font-semibold">
          Hi everyone! 37 years ago, I began my journey with Yoga. I learned about the different yoga poses and the most important thing: how to lose weight with Yoga. I worked on myself for several years and then started to share my knowledge on Instagram. From diet charts to yoga classes, I created a huge family that today has more than 100k members on my Instagram. Join now if you want to be a part of my family.
          </p>
          <div>
          <a href='https://www.instagram.com/monaayoga/'>
          <button className='py-2 px-7 sm:text-lg font-semibold bg-[#FF701F] text-white rounded-3xl border-2 border-primary hover:bg-white hover:text-primary'>Join Now</button>
          </a>
          
          </div>
        </div>
      </div>
    </div>
  </div>
  )
}

export default Arcu2