import React from "react";

const CancellationPolicy = () => {
  return (
    <div style={{ 
        backgroundImage : `url('/Assets/long.png')`,
        backgroundPosition: "center",
        backgroundSize: 'cover'
      }} className="px-[12%] lg:py-[10%] pb-[20%] pt-[30%]  relative bg-secondary">
      <h1 className="text-3xl font-bold mb-6 text-primary">Cancellation and Refund Policy</h1>
      <p className="mb-4 text-justify">
        At Monaa Yoga, we prioritize delivering high-quality digital products. As such, all purchases of e-books, courses, and other digital products are final and non-refundable.
      </p>
      <h2 className="text-2xl font-semibold mb-2 text-primary">No Cancellations</h2>
      <p className="mb-4 text-justify">
        Once a purchase is made, no cancellations or changes to the order can be processed.
      </p>
      <h2 className="text-2xl font-semibold mb-2 text-primary">Refunds</h2>
      <p className="mb-4 text-justify">
        Since all our products are digital, we do not offer refunds for any e-books or courses purchased through the website.
      </p>
      <h2 className="text-2xl font-semibold mb-2 text-primary">Non-Refundable Products</h2>
      <p className="mb-4 text-justify">
        All digital products, including e-books and courses, are non-refundable. Once a purchase is confirmed and the product is delivered, no refunds will be provided.
      </p>
      <h2 className="text-2xl font-semibold mb-2 text-primary">Contact Us</h2>
      <p className="mb-4 text-justify">
        For any further information, please reach out to:
      </p>
      <ul className="list-disc ml-6 mb-4">
        <li>Email: <strong>monaaranawat@gmail.com</strong></li>
        <li>Phone: <strong>+91 83830 78277</strong></li>
      </ul>
    </div>
  );
};

export default CancellationPolicy;
