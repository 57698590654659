import React from "react";
const PrivacyPolicy = () => {
  return (
    <div style={{ 
      backgroundImage : `url('/Assets/long.png')`,
      backgroundPosition: "center",
      backgroundSize: 'cover'
    }} className="px-[12%] lg:py-[10%] pb-[20%] pt-[30%]  relative bg-secondary">
     
      <h1 className="text-3xl font-bold mb-6 text-primary">Privacy Policy</h1>
      <p className="mb-4 text-justify">
        At Monaa Yoga, we value your privacy and are committed to protecting your personal information. This Privacy Policy outlines the types of information we collect, how we use it, and how we protect it.
      </p>
      <h2 className="text-2xl font-semibold mb-2 text-primary">Information We Collect</h2>
      <p className="mb-4 text-justify">
        When you sign up on our website to purchase e-books or courses, we collect the following information:
      </p>
      <ul className="list-disc ml-6 mb-4">
        <li>Name</li>
        <li>Email Address</li>
        <li>Phone Number</li>
        <li>Password (set by the user)</li>
      </ul>
      <p className="mb-4 text-justify">
        For payment transactions, we use <strong>Razorpay</strong> as our third-party payment processor, which handles all payment details. We do not store your payment information.
      </p>
      <h2 className="text-2xl font-semibold mb-2 text-primary">Third-Party Services and Cookies</h2>
      <p className="mb-4 text-justify">
        We use <strong>Razorpay</strong> for processing payments securely. We also utilize cookies to enhance your user experience. Cookies allow us to collect anonymous data that helps us improve website functionality and track usage patterns.
      </p>
      <h2 className="text-2xl font-semibold mb-2 text-primary">Data Protection</h2>
      <p className="mb-4 text-justify">
        We ensure that all personal information, such as your name, email, and phone number, is stored securely. We do not store any confidential or sensitive payment information on our servers. Security measures, including encryption and regular system checks, are in place to safeguard your data.
      </p>
      <h2 className="text-2xl font-semibold mb-2 text-primary">Information Sharing</h2>
      <p className="mb-4 text-justify">
        We do not sell, share, or trade your personal information with third parties. Your data is strictly used for communication and service purposes.
      </p>
      <h2 className="text-2xl font-semibold mb-2 text-primary">Contact Us</h2>
      <p className="mb-4 text-justify">
        If you have any concerns regarding your privacy or wish to review, update, or delete your personal information, please contact us at:
      </p>
      <ul className="list-disc ml-6 mb-4">
        <li>Email: <strong>monaaranawat@gmail.com</strong></li>
        <li>Phone: <strong>+91 83830 78277</strong></li>
      </ul>
    </div>
  );
};

export default PrivacyPolicy;
