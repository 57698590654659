import React from 'react'
const Home3 = () => {
  const List=[
    {
      name:"50k+",
      img:"Yoga Members"
    },
    {
      name:"200+",
      img:"Panihari Members"
    },
    {
      name:"100k+",
      img:"Diet Chart Members"
    },
    {
      name:"250+",
      img:"Workshop Members"
    },
  ]
  return (
    <div>

    <div className='px-6 md:px-8 lg:px-0 pt-12  pb-12 md:pb-0  md:xl:pl-32 lg:pl-20 bg-[#FFF5EF]'>
    <div className=''></div>
      <div className='grid lg:grid-cols-2 '>
        <div className='lg:pt-32 relative md:pl-12 order-2 lg:order-1 '>
        <img className='absolute hidden lg:block w-[50%] md:right-24 top-2  lg:right-[1%]' src="/Assets/Group20.png" alt="" />
            <p className='sm:text-[3.6vw] text-[6vw] xs:text-[5vw] font-bold'>Reach Out, <span className='text-[#FF701F] '>Let's Begin</span>  Your Wellness Journey  </p>
            <p className=' sm:text-lg md:w-[80%] lg:w-full py-6 md:text-xl font-semibold md:py-12 '>Have questions or need assistance? Feel free to contact us. We're here to support you on your path to well-being and answer any inquiries you may have.</p>
           
        </div>
        <div className='order-1 flex items-center justify-center lg:order-2'>
            <img className='h-[85%]' src="/Assets/Group19.png" alt="" />
        </div>
       

        </div>
      </div>
      <div className='bg-[#FFF5EF] pb-12 px-[12%]'>
        <div className='border-2 py-6 pb-12 px-10 rounded-3xl border-[#FFA36F]/30'>
          <h1 className='text-4xl   pb-8 font-semibold text-[#FF701F]'>Happy Clients</h1>
          <div className='grid xs:grid-cols-2 lg:grid-cols-4 gap-6'>
          {
            List.map((Lists)=>(
              <div className='bg-white shadow-4xl shadow-[#FF701F]/30  rounded-3xl flex flex-col py-10 items-center'>
                {/* <div className='h-16 w-16 bg-gray-400'></div> */}
                <h1 className="pb-2 text-3xl text-primary font-bold">{Lists.name}</h1>
                <p className="text-sm text-xl font-semibold">{Lists.img}</p>
              </div>
            ))
          }

          </div>
        </div>
      </div>
    </div>
  )
}

export default Home3