import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";

// import required modules
import { FreeMode, Autoplay } from "swiper/modules";

const Videos = () => {
  const List = [
    {
      id: "https://www.youtube.com/embed/_WwFtQ4v4oo?si=r66j5W0TGqSqU-Cc",
    },
    {
      id: "https://www.youtube.com/embed/RPjZrZf9HzM?si=DSwWbUfq-mm8DOZ6",
    },
    {
      id: "https://www.youtube.com/embed/VUIxtnij19s?si=9pgz_ASh4pslb9AH",
    },
    {
      id: "https://www.youtube.com/embed/zkfrq4atwzs?si=VRPnPAIW4wSb0H7e",
    },
    {
      id: "https://www.youtube.com/embed/d9vLW4s5Ung?si=r1ZjGkuqB0H2lVri",
    },
    {
      id: "https://www.youtube.com/embed/z-6ly-rJfQg?si=e1j83kypRo-qVe97",
    },
  ];
  const hard = [
    {
        id: "https://youtube.com/embed/-SbL1KOMZUg?feature=share"
    },
    {
        id: "https://youtube.com/embed/nHteZjHXxxM?feature=share"
    },
    {
        id: "https://youtube.com/embed/vCNb3Ew5drc?feature=share"
    },
    {
      id: "https://www.youtube.com/embed/CQi70hKpQ8U?si=zs6bA7pAVsdtPxVF",
    },
    {
        id: "https://youtube.com/embed/xXFC8tOkFbI?feature=share"
    },
    {
        id: "https://youtube.com/embed/g1KyItZaojg?feature=share"
    },
    {
        id: "https://youtube.com/embed/5QOMkuGMj90?feature=share"
    }
  ];
  return (
    <div>
      <div
        style={{
          backgroundImage: `url('/Assets/Group11.png'))`,
          backgroundSize: "cover",
        }}
      >
        <div className="">
          <h1 className="sm:text-5xl text-4xl pb-20 pt-20 py-6 font-semibold text-center">
            56 Hard <span className="text-[#FF701F] "> Weightloss</span>{" "}
            Challenge
          </h1>
          <Swiper
            slidesPerView={3}
            centeredSlides={true}
            grabCursor={true}
            spaceBetween={30}
            // freeMode={true}
            autoplay={{
              delay: 2500,
              disableOnInteraction: true,
            }}
            breakpoints={{
              0: {
                slidesPerView: 1,
                spaceBetween: 30,
              },
              640: {
                slidesPerView: 1,
                spaceBetween: 30,
              },
              768: {
                slidesPerView: 2,
                spaceBetween: 30,
              },
              1260: {
                slidesPerView: 3,
                spaceBetween: 30,
              },
            }}
            modules={[FreeMode, Autoplay]}
            className="  "
          >
            {hard.map((Lists) => (
              <SwiperSlide>
                <div className="grid place-content-center">
                  <iframe
                    src={Lists.id}
                    title="YouTube video player"
                    frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    allowfullscreen
                  ></iframe>
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
        <div>
        <h1 className="sm:text-5xl text-4xl  pt-32 py-6 font-semibold text-center">
            Surya <span className="text-[#FF701F] "> Namaskar</span>{" "}
            
          </h1>
        <div className="grid gap-6 py-20 lg:grid-cols-3 md:grid-cols-2">
          {List.map((Lists) => (
            <div className="grid place-content-center">
              <iframe
                src={Lists.id}
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowfullscreen
              ></iframe>
            </div>
          ))}
        </div>
        </div>
      </div>
    </div>
  );
};

export default Videos;
