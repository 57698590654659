import React from "react";

// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";

// import required modules
import { FreeMode, Autoplay } from "swiper/modules";

const Swip = () => {
  const List = [
    {
      img: "/Assets/w1.jpg",
      para: "Mission 5kg Workshop ",
    },
    {
      img: "/Assets/w2.jpg",
      para: "Workshop on Constipation",
    },
    {
      img: "/Assets/w3.jpg",
      para: "Mission 5kg Workshop",
    },
    {
      img: "/Assets/w4.jpg",
      para: "Break Fatloss Plateau ",
    },
    {
      img: "/Assets/w5.jpg",
      para: "Start Weightloss Journey ",
    },
  ];
  return (
    <div className="">
      <div
        className=" pb-28"
        style={{
          backgroundImage: `url('/Assets/Group11.png')`,
          backgroundSize: "cover",
        }}
        
      >
        <div className="flex flex-col  items-center">
          <h1 className="text-5xl pb-20 pt-20 py-6 font-semibold text-center">
            Past <span className="text-[#FF701F] "> Workshops</span>
          </h1>
          <div className=" w-full ">
            <Swiper
              slidesPerView={3}
              centeredSlides={true}
              grabCursor={true}
              spaceBetween={30}
              // freeMode={true}
              autoplay={{
                delay: 2500,
                disableOnInteraction: false,
              }}

              breakpoints={{
                0: {
                  slidesPerView: 1,
                  spaceBetween: 30,
                },
                640: {
                  slidesPerView: 1,
                  spaceBetween: 30,
                },
                768: {
                  slidesPerView: 2,
                  spaceBetween: 30,
                },
                1260: {
                  slidesPerView: 3,
                  spaceBetween: 30,
                },
              }}
              modules={[FreeMode, Autoplay]}
              className="  "
            >
              {List.map((Lists) => (
                <SwiperSlide>
                  <div className="relative h-[51vh]">
                    <div>
                      <img className="object-cover" src="/Assets/Group12.png" alt="" />
                    </div>
                    <div className="absolute top-0 left-16 rounded-3xl bg-primary w-[60%]">
                        <img className=" rounded-t-3xl" src={Lists.img} alt="" />
                        <h1 className="py-5 text-center text-white font-semibold text-xl">{Lists.para}</h1>
                    </div>
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Swip;
