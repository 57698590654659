import { Button, LoadingOverlay } from "@mantine/core";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

const ViewCourse = () => {
  const { user } = useSelector((state) => state);
  const navigate = useNavigate();
  if (!user?.selectedCourse) {
    navigate("/myCourse");
  }

  return (
    <>
      <LoadingOverlay
        visible={false}
        zIndex={100000}
        overlayProps={{ radius: "sm", blur: 1 }}
      />
      <div
        className="h-screen pt-20 overflow-auto"
        style={{
          backgroundImage: `url('/Assets/Group18.png')`,
          backgroundPosition: "center",
          backgroundSize: "cover",
        }}
      >
        <div className="mt-9 [-5">
          <h1 className="text-3xl font-bold w-full text-center text-primary">
            {user?.selectedCourse?.name}
          </h1>
        </div>

        <div className="flex justify-center flex-wrap gap-5 mt-10">
          {user?.selectedCourse?.bookUrl && (
            <div className="rounded-xl shadow-xl w-[350px] flex items-center justify-center flex-col">
              <img
                src={user?.selectedCourse?.bookCoverPageUrl}
                alt="book cover page"
                className="h-[450px] rounded-md mx-auto"
              />
              <Button className="m-5">
                <a href={user.selectedCourse.bookUrl} target="_blank">
                  Download Book
                </a>
              </Button>
            </div>
          )}
          {user &&
            user?.selectedCourse?.videos.map((item, index) => (
              <iframe
                key={`${index}-course-video-player`}
                width="560"
                height="315"
                src={`${item}?control=0`}
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share;"
                allowfullscreen
              ></iframe>
            ))}
        </div>
      </div>
    </>
  );
};

export default ViewCourse;
