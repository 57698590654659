import React from "react";

const TermsAndConditions = () => {
  return (
    <div style={{ 
        backgroundImage : `url('/Assets/long.png')`,
        backgroundPosition: "center",
        backgroundSize: 'cover'
      }} className="px-[12%] lg:py-[10%] pb-[20%] pt-[30%]  relative bg-secondary">
      <h1 className="text-3xl font-bold mb-6 text-primary">Terms and Conditions</h1>
      <p className="mb-4 text-justify">
        Welcome to Monaa Yoga. By using our website and purchasing our digital products, you agree to the following terms and conditions:
      </p>
      <h2 className="text-2xl font-semibold mb-2 text-primary">Digital Products</h2>
      <p className="mb-4 text-justify">
        All products sold on this website, including e-books and courses, are digital in nature and are delivered electronically. Once purchased, no physical items will be shipped.
      </p>
      <h2 className="text-2xl font-semibold mb-2 text-primary">Account Creation</h2>
      <p className="mb-4 text-justify">
        To access our e-books and courses, users are required to create an account by providing their name, email, phone number, and a password. Alternatively, users can sign up and log in via Google authentication.
      </p>
      <h2 className="text-2xl font-semibold mb-2 text-primary">No Returns or Exchanges</h2>
      <p className="mb-4 text-justify">
        As all products are digital, we do not offer any return or exchange policy once a purchase is completed.
      </p>
      <h2 className="text-2xl font-semibold mb-2 text-primary">Copyright and Use Restrictions</h2>
      <p className="mb-4 text-justify">
        All e-books and digital content available on Monaa Yoga are copyrighted. Redistribution, copying, or sharing of these products is strictly prohibited. Violations of this will be pursued under the appropriate legal channels.
      </p>
      <h2 className="text-2xl font-semibold mb-2 text-primary">Liability</h2>
      <p className="mb-4 text-justify">
        Monaa Yoga is not liable for any technical issues, disruptions, or other inconveniences that may arise from the use of the website or products. Our content is educational and for personal use; results may vary based on individual factors.
      </p>
      <h2 className="text-2xl font-semibold mb-2 text-primary">Contact Us</h2>
      <p className="mb-4 text-justify">
        For any queries or clarifications, please contact us at:
      </p>
      <ul className="list-disc ml-6 mb-4">
        <li>Email: <strong>monaaranawat@gmail.com</strong></li>
        <li>Phone: <strong>+91 83830 78277</strong></li>
      </ul>
    </div>
  );
};

export default TermsAndConditions;
