import { Menu, Button, rem, Avatar } from '@mantine/core';
import { SiCoursera } from "react-icons/si";
import { IoIosLogOut } from "react-icons/io";
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { clearUser } from '../../store/features/user';
import { notifications } from '@mantine/notifications';
import { FaRegCircleCheck } from "react-icons/fa6";

export const MenuButton = () => {
    
    const navigate = useNavigate()
    const dispatch = useDispatch()

    const handleLogout = () => {
        localStorage.setItem('token', "")
        notifications.show({ message: 'USER LOGOUT SUCCESSFULL', Title: 'Notification', icon: <FaRegCircleCheck /> })
        dispatch(clearUser())
        navigate("/login")
    }

    const navigateToCourses = () => {
        navigate('/myCourse')
    }

    return (
        <Menu width={200} shadow="md">
            <Menu.Target>
                <Avatar  radius="xl" size={'lg'}></Avatar>
            </Menu.Target>

            <Menu.Dropdown>
                <Menu.Item
                    leftSection={<SiCoursera size={22} />}
                    onClick={navigateToCourses}
                >
                    My Courses
                </Menu.Item>
                <Menu.Item
                    leftSection={<IoIosLogOut size={22} />}
                    onClick={handleLogout}
                >
                    logout
                </Menu.Item>
            </Menu.Dropdown>
        </Menu>
    );

}

export default MenuButton