import React, { useState } from "react";
import { TextInput, Stack, PasswordInput, Select } from "@mantine/core";
import { useForm, zodResolver } from "@mantine/form";
import { useNavigate } from "react-router-dom";
import { notifications } from "@mantine/notifications";
import { userSchema } from "../schemas/user.schema";
import { useDispatch } from "react-redux";
import { updateUser } from "../store/features/user";
import { useMutation } from "react-query";
import { signup } from "../endpoints/user";
import { LoadingOverlay, Box } from "@mantine/core";

const SignUp = () => {
  const [notificationId, SetNotificationId] = useState("");
  const navigate = useNavigate();

  const signUpMutation = useMutation({
    mutationFn: signup,
    mutationKey: ["user-singup"],
    onSuccess: (response) => {
      notifications.show({
        id: notificationId,
        message: "User Created",
        loading: false,
        title: "Success",
      });
      if (response?.success == true) {
        navigate("/login");
      }
    },
    onError: (err) => {
      notifications.show({
        id: notificationId,
        message: err.response.data.message.toUpperCase() || "!Error",
        title: "!Error",
        color: "red",
      });
    },
  });

  const form = useForm({
    initialValues: {
      name: "",
      email: "",
      password: "",
      phone: "",
      // height: "",
      // weight: "",
      // age: "",
      // workout: "",
      // sleep_schedule: "",
      // mental_status: "",
      // water_intake: "",
      // sleeping_time: "",
      // health_problems: "",
      // working_persion: "",
    },
    validate: zodResolver(userSchema),
  });

  const onClickLogin = async () => {
    navigate("/login");
  };

  const handleFormSubmition = async (values) => {
    signUpMutation.mutate(values);
  };

  return (
    <section className="bg-secondary h-screen w-full overflow-hidden">
      <LoadingOverlay
        visible={signUpMutation.isLoading}
        zIndex={1000}
        overlayProps={{ radius: "sm", blur: 0.5 }}
      />
      <div
        className="lg:grid grid-cols-2 place-content-center h-full w-full"
        style={{
          backgroundImage: `url('/Assets/sign.png')`,
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >
        <div className="place-content-center lg:grid hidden">
          <h1 className="text-primary text-5xl font-semibold text-center">
            Hello User!
          </h1>
          <h3 className="px-40 text-center py-10 text-2xl font-semibold">
            Existing User? Sign In to track your transformation!
          </h3>
          <button
            className="border-2 border-primary text-primary font-semibold px-7 py-2 hover:bg-primary hover:text-white rounded-3xl mx-auto"
            onClick={onClickLogin}
          >
            Sign In
          </button>
        </div>
        <div className="bg-white h-screen rounded-l-2xl grid place-content-center w-full overflow-auto">
          <h1 className="text-primary text-5xl font-semibold text-center md:mt-20 mt-[150px]">
            Sign Up!
          </h1>
          <h3 className="lg:px-40 px-5 text-center py-5 text-xl font-semibold">
            Please enter your details!
          </h3>
          <form
            className="lg:w-[80%] w-full mx-auto my-5"
            onSubmit={form.onSubmit(handleFormSubmition)}
          >
            <div>
              <Stack>
                <TextInput
                  label="Name"
                  placeholder="Enter name"
                  {...form.getInputProps("name")}
                ></TextInput>

                <TextInput
                  label="Phone Number"
                  placeholder="Enter phone number"
                  {...form.getInputProps("phone")}
                  type="number"
                />

                <TextInput
                  label="Email"
                  placeholder="Enter email"
                  type="email"
                  {...form.getInputProps("email")}
                />
                <PasswordInput
                  label="password"
                  placeholder="Enter password"
                  {...form.getInputProps("password")}
                />
              </Stack>
            </div>

            <button
              type="submit"
              className={
                "mt-5 lg:w-[80%] w-full mx-auto py-2 flex justify-center items-center rounded-3xl font-semibold border-2 border-primary hover:bg-white hover:text-primary"
              }
            >
              Submit
            </button>

            <div
              className="w-full text-center text-xl font-semibold lg:hidden pt-10 underline cursor-pointer"
              onClick={onClickLogin}
            >
              Existing user? Login
            </div>
          </form>
        </div>
      </div>
    </section>
  );
};

export default SignUp;
