import { server } from "../utils/server";
import { API_ROUTES } from "../utils/apiRoutes";
export const signup = async (body) => {
  const response = await server.post(API_ROUTES.USER.SIGNUP, body);
  return response.data;
};

export const login = async (body) => {
  const response = await server.post(API_ROUTES.USER.LOGIN, body);
  return response.data;
};

export const verifyToken = async () => {
  const response = await server.get(API_ROUTES.USER.AUTH_ME);
  return response.data;
};

/**
 *
 * @description get OTP for password reset
 */
export const getOTP = async ({ email }) => {
  const response = await server.post(API_ROUTES.USER.GET_OTP, { email });
  return response.data;
};

/**
 *
 * @description reset password
 */
export const resetPassword = async ({ email, password, otp }) => {
  const response = await server.post(API_ROUTES.USER.RESET_PASSWORD, {
    email,
    password,
    otp,
  });
  return response.data;
};

/**
 * @description login with google
 */

export const loginWithGoogle = async ({ token }) => {
  const response = await server.post(API_ROUTES.USER.LOGIN_WITH_GOOGLE, {
    token,
  });
  return response.data;
};
