import { useForm, zodResolver } from "@mantine/form";
import React from "react";
import { useNavigate } from "react-router-dom";
import { Stack, TextInput, PasswordInput, LoadingOverlay } from "@mantine/core";
import { notifications } from "@mantine/notifications";
import { loginSchema } from "../schemas/user.schema";
import { useDispatch } from "react-redux";
import { updateUser } from "../store/features/user";
import { useMutation } from "react-query";
import { login, loginWithGoogle } from "../endpoints/user";
import { MdErrorOutline } from "react-icons/md";
import { FaRegCircleCheck } from "react-icons/fa6";
import { GoogleLogin, GoogleOAuthProvider } from "@react-oauth/google";

const Login = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const loginMutation = useMutation({
    mutationFn: login,
    mutationKey: ["user", "login"],
    onSuccess: (response) => {
      if (response?.success) {
        dispatch(
          updateUser({
            email: response.data.email,
            id: response.data._id,
            name: response.data.name,
          })
        );
        notifications.show({
          message: "USER AUTHENTICATION SUCCESSFULL",
          title: "Notification",
          icon: <FaRegCircleCheck />,
        });
        localStorage.setItem("token", response?.token);
        navigate("/mycourse");
      }
    },
    onError: (err) => {
      notifications.show({
        message: err?.response?.data?.message.toUpperCase() || err.message,
        title: "Error",
        color: "red",
        icon: <MdErrorOutline />,
      });
    },
  });

  const loginWithGoogleMutation = useMutation({
    mutationFn: loginWithGoogle,
    onSuccess: (response) => {
      if (response?.success) {
        dispatch(
          updateUser({
            email: response.data.email,
            id: response.data._id,
            name: response.data.name,
          })
        );
        notifications.show({
          message: "USER AUTHENTICATION SUCCESSFULL",
          title: "Notification",
          icon: <FaRegCircleCheck />,
        });
        localStorage.setItem("token", response?.token);
        navigate("/mycourse");
      }
    },
    onError: (err) => {
      notifications.show({
        message: err?.response?.data?.message.toUpperCase() || err.message,
        title: "Error",
        color: "red",
        icon: <MdErrorOutline />,
      });
    },
  });

  const form = useForm({
    initialValues: {
      email: "",
      password: "",
    },
    validate: zodResolver(loginSchema),
  });

  const onClickLogin = () => {
    navigate("/signup");
  };

  const handlelogin = async (values) => {
    loginMutation.mutate(values);
  };

  const handleForgotPassword = () => {
    navigate("/forgot-password");
  };

  const handleGoogleLogin = (token) => {
    loginWithGoogleMutation.mutate({ token: token });
  };

  return (
    <GoogleOAuthProvider
      clientId={process.env.REACT_APP_GOOGLE_OAUTH_CLIENT_ID}
    >
      <section className="bg-secondary h-screen w-full overflow-hidden">
        <LoadingOverlay
          visible={loginMutation.isLoading}
          zIndex={1000}
          overlayProps={{ radius: "sm", blur: 1 }}
        />

        <div
          className="lg:grid grid-cols-2 place-content-center h-full w-full"
          style={{
            backgroundImage: `url('/Assets/sign.png')`,
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        >
          <div className="place-content-center lg:grid hidden">
            <h1 className="text-primary text-5xl font-semibold text-center">
              Hello User!
            </h1>
            <h3 className="px-40 text-center py-10 text-2xl font-semibold">
              New to Monaa's Fitness Aura? Sign Up for an amazing transformation
            </h3>
            <button
              className="border-2 border-primary text-primary font-semibold px-7 py-2 hover:bg-primary hover:text-white rounded-3xl mx-auto"
              onClick={onClickLogin}
            >
              Sign Up
            </button>
          </div>
          <div className="bg-white h-screen rounded-l-2xl grid place-content-center w-full">
            <h1 className="text-primary text-4xl font-semibold text-center">
              Sign In!
            </h1>
            <h3 className="lg:px-40 px-5 text-center py-5 text-xl font-semibold">
              Please enter your details!
            </h3>
            <form
              className="lg:w-[80%] w-full mx-auto my-10"
              onSubmit={form.onSubmit(handlelogin)}
            >
              <Stack>
                <TextInput
                  label="Email"
                  placeholder="Enter email"
                  {...form.getInputProps("email")}
                />
                <PasswordInput
                  label="Password"
                  placeholder="Enter Password"
                  {...form.getInputProps("password")}
                />
                <div className="grid my-4 gap-3 place-items-center">
                  <button
                    className="bg-primary lg:w-[80%] w-full mx-auto py-2 rounded-3xl font-semibold text-white border-2 border-primary hover:bg-white hover:text-primary"
                    type="submit"
                  >
                    Log In
                  </button>
                  {/* <button className="bg-light lg:w-[80%] w-full mx-auto py-2 flex justify-center items-center rounded-3xl font-semibold border-2 border-primary hover:bg-white hover:text-primary"><FcGoogle className="w-5 h-5 mx-5" /> Login with Google</button> */}

                  <GoogleLogin
                    onSuccess={(credentialResponse) => {
                      console.log(credentialResponse.credential);
                      handleGoogleLogin(credentialResponse.credential);
                    }}
                    onError={() => {
                      console.log("Login Failed");
                    }}
                    shape="circle"
                    ux_mode="popup"
                    useOneTap
                  />
                </div>
                <div
                  className="w-full text-center text-xl font-semibold lg:hidden underline cursor-pointer"
                  onClick={onClickLogin}
                >
                  New user? Sign Up
                </div>
                <div
                  className="w-full text-center font-semibold cursor-pointer underline"
                  onClick={handleForgotPassword}
                >
                  Forgot password
                </div>
              </Stack>
            </form>
          </div>
        </div>
      </section>
    </GoogleOAuthProvider>
  );
};

export default Login;
