import React from 'react'
import { Past, Pastb, Videos, Workshops } from '../components'

const Yoga = () => {
  return (
    <div>
        <Past />
        {/* <Workshops /> */}
        <Videos />

    </div>
  )
}

export default Yoga