import { useQuery } from "react-query";
import { verifyToken } from "../endpoints/user";
import { API_ROUTES } from "../utils/apiRoutes";
import { useDispatch } from "react-redux";
import { updateUser } from "../store/features/user";
import { notifications } from "@mantine/notifications";
import { FaCircleCheck } from "react-icons/fa6";

export const useVerifyToken = () => {
  const dispatch = useDispatch();

  return useQuery({
    queryFn: verifyToken,
    queryKey: [API_ROUTES.USER.AUTH_ME],
    onSuccess: (response) => {
      dispatch(updateUser(response.data));
      notifications.show({
        message: "LOGIN SUCCESSFULL",
        title: "Notification",
        icon: <FaCircleCheck />,
      });
    },
    onError: (err) => {
      notifications.show({
        message: "error while verifying token",
        title: "!error",
      });
      console.log("error while verifying token ---------------", { err });
    },
  });
};

export default useVerifyToken;
