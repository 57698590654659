import React from 'react'
import { Link } from 'react-router-dom';


const Monaa = () => {
  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };
  return (
    <div className='bg-[#FAFCF8] pt-20 lg:pt-0'>
    <div className='flex flex-wrap gap-20 md;gap-0 lg:flex-nowrap '>
        <div className='flex lg:w-[80%] relative lg:left-28 gap-10 flex-col justify-center px-6 md:px-20 lg:px-0'>
        <h1 className='sm:text-4xl leading-relaxed text-xl font-semibold'>A Healthy <span className='text-[#FF701F]'> Lifestyle</span> for Your Body & Soul - Monaa Yoga</h1>
        <p className='sm:text-lg  text-gray-500 sm:w-[80%]'>Discover the holistic benefits of yoga and make wellness a part of your daily life with Monaa Yoga. Experience balance, vitality, and lasting well-being through our expert guidance and a dedicated yoga practice.</p>
        <div>
          <Link to="/workshop" onClick={scrollToTop}>
        <button className='py-2 px-7 sm:text-lg font-semibold bg-[#FF701F] text-white rounded-3xl border-2 border-primary hover:bg-white hover:text-primary'>Explore Workshops</button>
          </Link>
        </div>

        </div>
        <div className='h-full'>
            <img className='md:h-[100vh] md:w-[100vw] object-cover' src="/Assets/Group7.png" alt="" />
        </div>
    </div>
      
    </div>
  )
}

export default Monaa