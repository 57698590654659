import React from "react";

const Panihari = () => {
  const List = [
    {
      img: "",
      title: "20+",
      abt: " Successful Batches",
    },
    {
      img: "",
      title: "200+",
      abt: " Panihari Members",
    },
  ];
  return (
    <div className="bg-[#FFF5EF] py-20 w-full">
      <div className="grid lg:grid-cols-2 w-full">
        <div className="xl:pl-[20%] :w-[80%] lg:w-full flex flex-col justify-center items-center px-6 sm:pl-[10%] 2xl:pl-[25%] pt-10  ">
          <h1 className="text-5xl  pb-12 pt-6 font-bold text-[#FF701F] ">
            Panihari
          </h1>
          <p className="text-xl  xl:w-[80%] text-gray-600 font-semibold">
          Panihari: A Soulful Treat of Traditional Flavors and Wholesome Delights. Savor the {" "}
            <span className="text-[#FF701F]">Authentic Taste </span> of Heritage.
          </p>

          <p className="text-xl pt-8 xl:w-[80%] text-gray-600 font-semibold">
            {" "}The Easy Way to 
            <span className="text-[#FF701F]"> Weight Loss with Panihari. </span>Join Now and See the Difference, the Healthy Way!{" "}
          </p>
          <div className="grid gap-6 md:w-[70%] lg:w-full place-items-center sm:grid-cols-2 pt-12 xl:w-[85%]">
            {List.map((Lists) => (
              <div className="bg-white gap-2 w-[70%] w-full   text-center m-2 p-5 shadow-3xl shadow-[#FFA488]/30 rounded-3xl flex flex-col items-center justify-center">
                {/* <div className="w-12 h-12 bg-slate-500"></div> */}
                <h1 className="pb-2 text-3xl text-primary font-bold">{Lists.title}</h1>
                <p className="text-sm text-xl font-semibold">{Lists.abt}</p>
              </div>
            ))}
          </div>
        </div>
        <div className="flex justify-center">
          <img className="px-10 lg:px-0" src="/Assets/Group8.png" alt="" />
        </div>
      </div>
    </div>
  );
};

export default Panihari;