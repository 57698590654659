import React from 'react'
import { Arcu, Home3, Morbi2, Panihar, Orange } from '../components'

const Contact = () => {
  return (
    <div>
        <Home3 />
        <Morbi2 />
        <Panihar />
        <Arcu />
        <Orange />
    </div>
  )
}

export default Contact