import React from "react";

const Arcu = () => {
  return (
    <div className="bg-[#FFF5EF] ">
      <div
        className="py-[5%] "
        style={{
          backgroundImage: `url('/Assets/Group23.png')`,
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >
        <div className="grid lg:grid-cols-2">
          <div className="">
            <img className="" src='/Assets/Groupdiet.png' alt='' />
          </div>
          <div className="sm:w-[80%] md:w-[70%] pl-[10%] lg:pl-0 grid place-content-center">
            <h1 className="2xl:text-4xl sm:text-2xl  text-xl lg:text-3xl   font-bold">
              Personalised <span className="text-[#FF701F]">Diet Plan:</span>{" "}
              Tailored Nutrition for Your Health Goals
            </h1>

            <p className=" sm:text-lg py-12 xl:w-[90%] text-gray-600 font-semibold">
            Discover a diet made for you. Reach your health goals with a personalized nutrition plan designed for your success. Whether you aim for weight loss, enhanced energy, or improved well-being, we've got you covered. Get your personalised diet plan today!
            </p>
            <div>
              <a href="https://api.whatsapp.com/send/?phone=918383078277&text=Hi%2C%0D%0A%0D%0AI+would+like+to+have+a+personalised+diet+plan.&type=phone_number&app_absent=0">
            <button className='py-2 px-7 sm:text-lg font-semibold bg-[#FF701F] text-white rounded-3xl border-2 border-primary hover:bg-white hover:text-primary'>Get Personalised Diet Plan</button>
              </a>
            </div>
            
          </div>
        </div>
      </div>
    </div>
  );
};

export default Arcu;