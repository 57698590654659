import { API_ROUTES } from "../utils/apiRoutes"
import { server } from "../utils/server"

export const GetALLCourses = async () => {
    const response = await server.get('/course')
    return response.data
}

export const getUserCourse = async () => {
    const res = await server.get(API_ROUTES.USER.COURSES)
    return res.data
}