import React from 'react'
import { Link } from 'react-router-dom'


const Morbi = () => {
  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };
  return (
    <div className='py-24'>
      <div className='grid lg:grid-cols-2'>
        <div className='flex justify-center'>
            <img  src="/Assets/Group3.png" alt="" />
        </div>
        <div className='xl:pr-28 px-6 sm:px-10 md:px-20 lg:pr-20 flex flex-col justify-center gap-12'>
        <h1 className='xl:text-4xl sm:text-2xl text-xl font-bold '>Monaa Yoga: <span className='text-[#FF701F]'>37 Years</span> of Wellness Mastery</h1>
        <p className='text-lg text-gray-600 font-semibold'>Discover the benefits of increased flexibility, reduced stress, and enhanced well-being. Join our friendly community and start your transformation towards a happier, healthier you. Monaa Yoga is your gateway to wellness through breathwork and yoga.</p>
        <div className='flex justify-between relative'>
        <div className=''>
          <Link to='/yoga' onClick={scrollToTop}>
        <button className='py-2 px-10 sm:text-lg font-semibold bg-[#FF701F] text-white rounded-3xl border-2 border-primary hover:bg-white hover:text-primary'>Learn Yoga</button>
          </Link>
        </div>
        <div className='absolute -top-52 sm:-top-10 right-0'>
            <img className='w-[90%]' src="/Assets/Group4.png" alt="" />
        </div>

        </div>

        </div>
      </div>
    </div>
  )
}

export default Morbi