import React from 'react'
import { Arcu2, Feedback, Home4, Result } from '../components'

const About = () => {
  return (
    <div>
        <Home4 />
        <Result />
        <Arcu2 />
        <Feedback />
    </div>
  )
}

export default About