import { LoadingOverlay } from "@mantine/core";
import { Card, Image, Text, Group } from "@mantine/core";
import { getUserCourse } from "../endpoints/courses";
import { API_ROUTES } from "../utils/apiRoutes";
import { useQuery } from "react-query";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { updateUser } from "../store/features/user";
import { Link, useNavigate } from "react-router-dom";

const CourseCard = ({
  thumbnail,
  description,
  name,
  id,
  videos,
  bookUrl,
  bookCoverPageUrl,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const handleClick = () => {
    dispatch(
      updateUser({
        selectedCourse: {
          thumbnail,
          description,
          name,
          id,
          videos,
          bookUrl,
          bookCoverPageUrl,
        },
      })
    );
    navigate("/viewCourse");
  };
  return (
    <Card
      shadow="sm"
      padding="lg"
      radius="md"
      withBorder
      w={300}
      className="relative cursor-pointer"
      onClick={handleClick}
    >
      <Card.Section>
        <Image src={thumbnail} height={160} alt="Norway" />
      </Card.Section>

      <Group justify="space-between" mt="md" mb="xs">
        <Text fw={500}>{name}</Text>
      </Group>

      <Text size="sm" c="dimmed" className="max-h-[100px] overflow-auto">
        {description}
      </Text>
    </Card>
  );
};

const MyCourses = () => {
  const { user } = useSelector((state) => state);
  const [userCourses, setUserCourses] = useState([]);
  const navigate = useNavigate();

  if (!user?.email) {
    navigate("/login");
  }

  const { isLoading } = useQuery({
    queryFn: getUserCourse,
    queryKey: [API_ROUTES.USER.COURSES],
    onSuccess: (response) => {
      if (response.data) {
        setUserCourses(response.data);
      }
    },
  });

  return (
    <>
      <LoadingOverlay
        visible={isLoading}
        zIndex={100000}
        overlayProps={{ radius: "sm", blur: 1 }}
      />
      <div
        className="h-screen pt-20 overflow-auto"
        style={{
          backgroundImage: `url('/Assets/Group18.png')`,
          backgroundPosition: "center",
          backgroundSize: "cover",
        }}
      >
        <div className="my-10 p-5 flex gap-5 justify-center flex-wrap ">
          {userCourses.map((item, index) => (
            <CourseCard
              description={item?.course?.description}
              id={item._id}
              name={item?.course?.name}
              thumbnail={item?.course?.thumbnail}
              key={`${index}-user-courses`}
              videos={item?.course?.videos}
              bookUrl={item?.course?.bookUrl || null}
              bookCoverPageUrl={item?.course?.bookCoverPageUrl}
            />
          ))}
        </div>

        {userCourses.length == 0 ? (
          <div className=" flex flex-col justify-center items-center pt-44">
            <div>
              <h1 className="xs:text-[7vw] pb-12 text-4xl font-semibold">
                Enroll <span className="text-[#FF701F]">in a Course</span>
              </h1>
            </div>

            <p className="sm:text-xl text-center py-8 pb-12 sm:w-[60%] w-[70%] md:w-[50%] lg:w-[40%] xl:w-[33%]">
              Exciting Courses Coming Your Way! Enroll in a course for an
              Unforgettable Learning Experience with Monaa Yoga{" "}
            </p>
            <Link to="/courses">
              <button className="bg-[#FF701F] font-semibold rounded-full text-white py-2 border-2 border-primary hover:bg-white hover:text-primary px-12 ">
                Explore Courses
              </button>
            </Link>
          </div>
        ) : null}
      </div>
    </>
  );
};

export default MyCourses;
