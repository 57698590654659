import React from 'react'

const Home4 = () => {
  const List=[
    {
      name:"50+",
      img:"Workshops Attended"
    },
    {
      name:"20+",
      img:"Courses Done"
    },
    {
      name:"10+",
      img:"Cities Travelled"
    },
  ]
  return (
    <div className='bg-[#FFF5EF] pb-12'>

    <div className='px-6 md:px-8 lg:px-0 pt-12  pb-12 md:pb-0  md:xl:pl-32 lg:pl-20 '>
    <div className=''></div>
      <div className='grid lg:grid-cols-2 '>
        <div className='flex flex-col pt-12  justify-center relative md:pl-12 order-2 lg:order-1 '>
        <img className='absolute hidden lg:block w-[50%] md:right-24 top-10  lg:left-[5%]' src="/Assets/Group20.png" alt="" />
            <p className='sm:text-[3.6vw]  text-[6vw] xs:text-[5vw] font-bold'><span className='text-[#FF701F] '>Monaa Yoga </span>Your Trusted Wellness Mentor and Yoga Expert</p>
            <p className=' sm:text-lg  py-6 md:text-xl font-semibold md:py-12 '>Experience the wealth of wisdom from 37 years of yoga mastery by Monaa, your trusted wellness mentor. Gain from her unrivaled expertise as you start a transformative journey towards holistic health and vitality.</p>
           
        </div>
        <div className='order-1 flex items-center justify-center lg:justify-start lg:order-2'>
            <img className='' src="/Assets/Group26.png" alt="" />
        </div>
       

        </div>
      </div>
      <div className='  px-[12%]'>
        <div className='border-2 py-6 pb-12 px-10 rounded-3xl border-[#FFA36F]/30'>
          <h1 className='text-4xl   pb-8 font-semibold text-[#FF701F]'>37 Years of Experience</h1>
          <div className='grid xs:grid-cols-2 lg:grid-cols-3 gap-6'>
          {
            List.map((Lists)=>(
              <div className='bg-white shadow-4xl shadow-[#FF701F]/30  rounded-3xl flex flex-col py-10 items-center'>
                {/* <div className='h-16 w-16 bg-gray-400'></div> */}
                <h1 className="pb-2 text-3xl text-primary font-bold">{Lists.name}</h1>
                <p className="text-sm text-xl font-semibold">{Lists.img}</p>
              </div>
            ))
          }

          </div>
        </div>
      </div>
        </div>
  )
}

export default Home4