import React from "react";


const Morbi2 = () => {
  return (
    <div className="bg-[#FFF5EF] ">
      <div className="py-24">
        <div className="grid lg:grid-cols-2 ">
          <div className="flex flex-col relative justify-end   pb-[12%]">
          <img src="/Assets/Group22.png" className="absolute right-0 top-12" alt="" />
            <img className="" src="/Assets/Group21.png" alt="" />
          </div>
          <div className="xl:pr-20  px-6 sm:pl-[10%]  lg:pr-20 flex flex-col justify-center gap-8">
          <h1 className="xl:text-3xl  pb-6  text-xl font-bold ">
              <span className="text-[#FF701F]">Join Panihari: </span>{" "}
              Embrace a Healthier You Through Our Exclusive Weight Loss Program
            </h1>
            <div className="pl-[8%]">
            <p className="xl:text-2xl text-lg font-semibold"> Efficient <span className="text-[#FF701F]">Weightloss</span>{" "}
               </p>
               <p className="xl:w-[80%]">Shed those extra pounds effectively with Panihari. Achieve your weight goals through a healthy and sustainable approach.</p>
                
            </div>
            <div className="pl-[8%]">
            <p className="xl:text-2xl text-lg font-semibold"> Effective <span className="text-[#FF701F]">Diet Charts</span>{" "}
               </p>
               <p className="xl:w-[80%]">Customized dietary plans to support your weight loss journey and improve your overall well-being.</p>
                
            </div>
            <div className="pl-[8%]">
            <p className="xl:text-2xl text-lg font-semibold"> <span className="text-[#FF701F]">Yoga</span>{" "}
               Integration</p>
               <p className="xl:w-[80%]">Panihari combines the power of yoga with weight loss strategies. Enhance your flexibility, strength, and mental clarity with Panihari.</p>
                
            </div>
          
            <div className="flex justify-between pl-[8%] relative">
              <div className="">
                <a href="https://api.whatsapp.com/send/?phone=918383078277&text=Hi%2C%0D%0A%0D%0AI+would+like+to+join+your+Panihari+program&type=phone_number&app_absent=0">
              <button className='py-2 px-7 sm:text-lg font-semibold bg-[#FF701F] text-white rounded-3xl border-2 border-primary hover:bg-white hover:text-primary'>Enroll Panihari</button>
                </a>
              </div>
              <div className="absolute -top-52 sm:-top-10 right-0">
                <img className="w-[90%]" src="/Assets/Group4.png" alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Morbi2;