import { useNavigate } from "react-router-dom";
import { API_ROUTES } from "../../utils/apiRoutes";
import { server } from "../../utils/server";

export const usePaymentGateway = () => {
  const navigate = useNavigate();

  const openPaymentGateway = ({
    order_id,
    amount,
    currency,
    course_name,
    description,
    image,
    prefill,
    theme,
  }) => {
    const options = {
      key: process.env.REACT_APP_RAZORPAY_KEY,
      amount: amount,
      currency: currency,
      name: course_name,
      description: description,
      image: image,
      order_id: order_id,
      prefill: prefill,
      theme: theme,
      handler: async (response) => {
        try {
          await server.post(API_ROUTES.PAYMENT.VERIFY, response);
          navigate("/myCourse");
        } catch (error) {
          await server.post(API_ROUTES.PAYMENT.FAILED, { orderId: order_id });
          console.log({ error });
        }
      },
    };
    const rzp1 = new window.Razorpay(options);
    try {
      rzp1.open();
    } catch (error) {
      console.log(error);
    }
  };

  return {
    openPaymentGateway,
  };
};
