import React from "react";

const Panihar = () => {
  return (
    <div className=" md:py-12 pb-12 w-full">
      <div className="grid lg:grid-cols-2 ">
        <div>
          <img src="/Assets/Group24.png" alt="" />

          <div className="flex xs:pl-[10%] justify-center  flex-col items-center ">
            <div className="  lg:w-full md:pt-[10%]  px-6 sm:pl-[10%] lg:pl-[0%] xl:pl-[20%] 2xl:pl-[20%]   ">
              <p className="2xl:text-4xl sm:text-2xl  text-xl lg:text-3xl   font-bold">
              37 Years of <span className="text-[#FF701F]">Yoga Expertise: </span>{" "}
              Join Our Yoga Classes for a Transformational Journey
              </p>

              <p className=" sm:text-lg py-12  text-gray-600 font-semibold">
              Benefit from a wealth of experience as you join our yoga classes guided by Monaa mam, with 37 years of deep expertise. Begin your yoga journey and unlock a healthier, more balanced life with us.
              </p>
              <a href="https://api.whatsapp.com/send/?phone=918383078277&text=Hi%2C%0D%0A%0D%0AI+would+like+to+join+your+yoga+classes&type=phone_number&app_absent=0">
              <button className='py-2 px-7 sm:text-lg font-semibold bg-[#FF701F] text-white rounded-3xl border-2 border-primary hover:bg-white hover:text-primary'>Join Yoga Classes</button>
              </a>
            </div>
          </div>
        </div>
        <div className="flex xs:pr-[10%] justify-center">
          <img className="px-10 lg:px-0" src="/Assets/Group8.png" alt="" />
        </div>
      </div>
    </div>
  );
};

export default Panihar;