import { Button, LoadingOverlay, TextInput } from "@mantine/core";
import { useForm, zodResolver } from "@mantine/form";
import { useMutation } from "react-query";
import { z } from "zod";
import { getOTP, resetPassword } from "../endpoints/user";
import { API_ROUTES } from "../utils/apiRoutes";
import { notifications } from "@mantine/notifications";

const getOTPSchema = z.object({
  email: z.string().email({ message: "Invalid email address" }),
});

const resetPasswordSchema = z.object({
  otp: z.string().min(2, "Invalid OTP"),
  password: z.string().min(2, "Invalid password"),
});

const ForgotPassword = () => {
  const getOtpMutation = useMutation({
    mutationFn: getOTP,
    mutationKey: [API_ROUTES.USER.GET_OTP],
    onSuccess: (response) => {
      notifications.show({ message: response?.message });
    },
    onError: (err) => {
      notifications.show({
        message:
          err?.response?.data?.message.toUpperCase() || err.message || "!Error",
      });
    },
  });

  const resetPasswordMutation = useMutation({
    mutationFn: resetPassword,
    mutationKey: [API_ROUTES.USER.RESET_PASSWORD],
    onSuccess: (response) => {
      notifications.show({ message: response?.message });
    },
    onError: (err) => {
      console.log(err);
      notifications.show({
        message:
          err?.response?.data?.message.toUpperCase() || err.message || "!Error",
      });
    },
  });

  const getOtpForm = useForm({
    initialValues: { email: "" },
    validate: zodResolver(getOTPSchema),
  });

  const resetPasswordForm = useForm({
    initialValues: {
      otp: "",
      password: "",
    },
    validate: zodResolver(resetPasswordSchema),
  });

  const handleGetOTP = (values) => {
    getOtpMutation.mutate({ email: values?.email });
  };

  const handlePasswordReset = (values) => {
    resetPasswordMutation.mutate({
      otp: Number(values?.otp),
      password: values?.password,
      email: getOtpForm.values.email,
    });
  };

  return (
    <div className="h-screen flex items-center justify-center flex-col">
      <LoadingOverlay
        visible={getOtpMutation.isLoading || resetPasswordMutation.isLoading}
        zIndex={100000}
        overlayProps={{ radius: "sm", blur: 1 }}
      />
      <h1 className="text-2xl font-semibold mb-5">Forgot Password</h1>
      <form
        className="w-[300px] flex flex-col gap-4"
        onSubmit={getOtpForm.onSubmit(handleGetOTP)}
      >
        <TextInput
          label="Email"
          description="Enter your email address"
          placeholder="example@example.com"
          {...getOtpForm.getInputProps("email")}
        />
        <Button type="submit"> Get OTP</Button>
      </form>

      {getOtpForm.isValid() && (
        <form
          className="w-[300px] mt-8 flex flex-col gap-3"
          onSubmit={resetPasswordForm.onSubmit(handlePasswordReset)}
        >
          <TextInput
            label="OTP"
            description="Enter OTP"
            placeholder="1234"
            type="number"
            {...resetPasswordForm.getInputProps("otp")}
          />
          <TextInput
            label="New Password"
            description="Enter new password"
            placeholder="sample@12"
            {...resetPasswordForm.getInputProps("password")}
          />
          <Button type="submit">Submit</Button>
        </form>
      )}
    </div>
  );
};

export default ForgotPassword;
