import React from 'react'

const Home2 = () => {
  return (
    <div className='px-6 md:px-8 lg:px-0 pt-12    md:xl:pl-32 lg:pl-20 bg-[#FFF5EF]'>
    <div className=''></div>
      <div className='grid lg:grid-cols-2 '>
        <div className='md:pt-32 relative md:pl-12 order-2 lg:order-1 '>
        <img className='absolute w-[35%] md:right-[31%] lg:right-30 md:top-24 -top-[2%] opacity-60 right-[28%] lg:right-[19%]' src="/Assets/Group10.png" alt="" />
            <h1 className='xl:text-5xl sm:text-3xl md:text-4xl  text-2xl  font-bold   leading-relaxed'>  Master Your  <span className='text-[#FF701F]'>Wellness </span> </h1>
            <p className=' sm:text-lg SGE5 py-6 md:text-xl font-semibold md:py-12 '>Delve into specialized workshops by Monaa Yoga, where expert guidance and immersive experiences await. Uncover new dimensions of well-being, deepen your practice, and embrace a balanced, rejuvenated life. Your journey towards a healthier you begins here.</p>
            {/* <button className='py-2 px-7 sm:text-lg font-semibold bg-[#FF701F] text-white rounded-3xl border-2 border-primary hover:bg-white hover:text-primary'>Join Now</button> */}

        </div>
        <div className='order-1 flex justify-center lg:order-2'>
            <img className='h-[85%]' src="/Assets/Group.png" alt="" />
        </div>
       

        </div>
      </div>
  )
}

export default Home2